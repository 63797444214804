<template>
    <div>
      <national-trademark-search-list></national-trademark-search-list>
    </div>
  </template>
  
  <script>
  
  export default {
  }
  </script>
  
  
  
  